/*
Note to reader: This CSS is bad - and that's okay! This is
usually written at 10:30pm right before I go to bed whenever
I decide it would be a good idea to add updates.
*/

html {
  background: black;
}

body {
  flex-direction: column;
}

.container {
  display: flex;
  width: 100vw;
  height: 100dvh;
  align-items: center;
  justify-content: center;
  position: relative;
}

.container--portfolio {
  padding: 64px 0 32px 0;
  align-items: flex-start;
  height: max-content;
}

.scale {
  @media (max-width: 700px) {
    transform: scale(0.75);
  }

  @media (max-width: 500px) {
    transform: scale(0.6);
  }

  @media (max-width: 400px) {
    transform: scale(0.5);
  }
}

.webgl {
  outline: none;
  position: absolute;
  cursor: pointer;
}

.gap {
  width: 640px;
  height: 445px;
}

.content {
  width: calc(640px - 32px);
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: white;
  margin: 0;
}

h1 {
  font-size: 1rem;
}

h2 {
  font-size: 0.8rem;
}

h3 {
  font-size: 0.8rem;
}

p {
  color: white;
  font-size: 0.7rem;
  line-height: 1rem;
}

.link {
  text-decoration: underline;
  color: white;
}

.icons {
  color: white;
  display: flex;
  padding: 8px 8px 8px 0px;
  gap: 16px;
  font-size: 18px;
}

i {
  cursor: pointer;
}

.more-content {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 64px;
}

.column {
  width: 640px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.line {
  width: calc(640px - 32px);
  height: 1px;
  background: rgb(205, 205, 205);
  margin-bottom: 24px;
  max-width: calc(100vw - 48px);
}

iframe {
  width: 640px;
  height: 480px;
  max-width: 90vw;
  outline: 1px solid white;
}

.top {
  margin-top: 12px;
}

a {
  color: white;
}

.iframe-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: lightgray;
  font-size: 12px;
}

.name {
  margin-top: 4px;
}
